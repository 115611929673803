import React from "react"

export const dataHero = {
  eyebrow: "Introducing Gatsby Design Labs",
  title: "Help shape the future of Gatsby!",
  lede:
    "Sign up for our user research program and help build the product you love.",
}

export const dataHow = [
  {
    id: 1,
    title: "Sign up",
    content:
      "Sign up for the Gatsby Design Labs user research program using the form below, giving us insight into how you use Gatsby and what types of studies you'd like to help with.",
    image: () => (
      <svg
        width={140}
        height={105}
        viewBox="0 0 140 105"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={55.5} cy={48.5} r={48.5} fill="#BD91E8" />
        <path fill="#fff" d="M91.055 83.353h-71.53v-71.53h71.53z" />
        <path
          stroke="#7026B9"
          d="M90.555 83.353v-71.53M72.672 83.353v-71.53M54.79 83.353v-71.53M36.908 83.353v-71.53M19.025 83.353v-71.53M19.525 82.853h71.53M19.525 64.971h71.53M19.525 47.088h71.53M19.525 29.206h71.53M19.525 11.323h71.53"
        />
        <circle
          cx={37.275}
          cy={65.157}
          r={17.696}
          transform="rotate(-180 37.275 65.157)"
          fill="#7026B9"
          stroke="#7026B9"
        />
        <path
          d="M90.608 29.392c-9.11 0-9.11 17.569-17.786 17.569-8.675 0-8.675-17.569-17.351-17.569"
          stroke="#7026B9"
          strokeLinecap="round"
          strokeDasharray="2 4"
        />
        <path
          d="M107.579 46.96c-8.985 0-8.985-17.568-17.971-17.568"
          stroke="#7026B9"
          strokeDasharray="2 4"
        />
        <path
          d="M55.47 29.392c-9.434 0-9.434 17.569-18.42 17.569-8.986 0-8.986-17.569-17.972-17.569"
          stroke="#7026B9"
        />
      </svg>
    ),
  },
  {
    id: 2,
    title: "Participate",
    content:
      "When you're selected for a study, we’ll email you details about the type of study we're running and our timeframe. Studies take place online — all you need is a computer and a great internet connection.",
    image: () => (
      <svg
        width={140}
        height={105}
        viewBox="0 0 140 105"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={56.5} cy={49.5} r={49.5} fill="#F06200" />
        <path fill="#fff" d="M54.684 11.226h57.712v57.71H54.683z" />
        <path
          stroke="#639"
          d="M55.184 11.226v57.711M112.896 11.226v57.711M112.396 11.726H54.684M112.396 69.437H54.684"
        />
        <path
          fill="#fff"
          stroke="#639"
          d="M53.242 9.783h3.607v3.607h-3.607zM110.953 9.783h3.607v3.607h-3.607zM53.242 67.494h3.607v3.607h-3.607zM110.953 67.494h3.607v3.607h-3.607z"
        />
        <circle
          cx={97.968}
          cy={54.87}
          r={14.428}
          transform="rotate(-90 97.968 54.87)"
          fill="#639"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.884 76.126l12.884-4.56-34.627-18.957 10.095 38.043 7.559-11.345 9.051 11.535 4.089-3.18-9.051-11.536z"
          fill="#F06200"
        />
      </svg>
    ),
  },
  {
    id: 3,
    title: "Pay it forward",
    content:
      "In the spirit of paying it forward, at the end of each study you can select a non-profit STEM organization that Gatsby will donate to on your behalf.",
    image: () => (
      <svg
        width="140"
        height="105"
        viewBox="0 0 140 105"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#design-labs-clip-path)">
          <circle cx="56.5" cy="49.5" r="49.5" fill="#159BF3" />
          <path
            d="M68.5 73.9311C82.6848 73.9311 94.1839 85.1497 94.1839 98.9885C94.1839 98.9885 82.6848 98.9885 68.5 98.9885C54.3152 98.9885 42.8161 98.9885 42.8161 98.9885C42.8161 85.1497 54.3152 73.9311 68.5 73.9311Z"
            fill="white"
          />
          <mask id="design-labs-mask" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M57.1871 8.78162C43.0227 8.78162 31.5402 20.2641 31.5402 34.4285C31.5402 48.5929 43.0227 60.0754 57.1871 60.0754H60.1931L69.0372 68.9195L77.8814 60.0754H80.5025C94.6669 60.0754 106.149 48.5929 106.149 34.4285C106.149 20.2641 94.6669 8.78162 80.5025 8.78162H57.1871Z"
            />
          </mask>
          <path
            d="M60.1931 60.0754L63.0215 57.247L61.8499 56.0754H60.1931V60.0754ZM69.0372 68.9195L66.2088 71.748L69.0372 74.5764L71.8656 71.748L69.0372 68.9195ZM77.8814 60.0754V56.0754H76.2245L75.0529 57.247L77.8814 60.0754ZM35.5402 34.4285C35.5402 22.4733 45.2319 12.7816 57.1871 12.7816V4.78162C40.8136 4.78162 27.5402 18.055 27.5402 34.4285H35.5402ZM57.1871 56.0754C45.2319 56.0754 35.5402 46.3838 35.5402 34.4285H27.5402C27.5402 50.802 40.8136 64.0754 57.1871 64.0754V56.0754ZM60.1931 56.0754H57.1871V64.0754H60.1931V56.0754ZM71.8656 66.0911L63.0215 57.247L57.3647 62.9038L66.2088 71.748L71.8656 66.0911ZM75.0529 57.247L66.2088 66.0911L71.8656 71.748L80.7098 62.9038L75.0529 57.247ZM80.5025 56.0754H77.8814V64.0754H80.5025V56.0754ZM102.149 34.4285C102.149 46.3838 92.4577 56.0754 80.5025 56.0754V64.0754C96.876 64.0754 110.149 50.802 110.149 34.4285H102.149ZM80.5025 12.7816C92.4577 12.7816 102.149 22.4733 102.149 34.4285H110.149C110.149 18.055 96.876 4.78162 80.5025 4.78162V12.7816ZM57.1871 12.7816H80.5025V4.78162H57.1871V12.7816Z"
            fill="white"
            mask="url(#design-labs-mask)"
          />
          <circle
            cx="54.8556"
            cy="34.4285"
            r="4.66307"
            fill="white"
            fillOpacity="0.9"
          />
          <circle
            cx="68.8449"
            cy="34.4285"
            r="4.66307"
            fill="white"
            fillOpacity="0.9"
          />
          <circle
            cx="82.8341"
            cy="34.4285"
            r="4.66307"
            fill="white"
            fillOpacity="0.9"
          />
        </g>
        <defs>
          <clipPath id="design-labs-clip-path">
            <rect width="140" height="105" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
]

export const dataForm = {
  eyebrow: "Curious? Ready?",
  heading: "Sign up",
  form: {
    id: "foobar",
    marketoFormCampaignObject: {
      formId: "1270",
      name: "string",
    },
    thankYouMessage: {
      childMarkdownRemark: {
        html:
          "Thanks for your interest in Gatsby Labs! You'll get a confirmation email from our design team shortly. Cheers!",
      },
    },
  },
}
